<template>
	<v-container>
		<p class="mb-1">
			<v-slider
				prepend-icon="mdi-magnify"
				@change="(v) => $emit('input', 'zoomLevel', v)"
				:value="zoomLevel"
				:min="minZoom"
				:max="maxZoom"
			></v-slider>
		</p>
		<timezone-selector
			:value="timezone"
			:multiple="false"
			label="Change timezone view"
			@input="(v) => $emit('input', 'timezone', v)"
		></timezone-selector>
		<v-checkbox :value="pastIsHidden" label="Hide past dates" @change="v => $emit('hidePast', v)"></v-checkbox>
	</v-container>
</template>

<script>
import TimezoneSelector from "@c/ui/TimezoneSelector";
export default {
	name: "SchedulerToolbar",
	props: {
		zoomLevel: { type: Number },
		minZoom: { type: Number, default: 0 },
		maxZoom: { type: Number, default: 100 },
		timezone: { type: String },
		pastIsHidden: { type: Boolean },
	},
	components: {
		TimezoneSelector,
	},
};
</script>
, SchedulerInstructions
