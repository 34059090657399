<template>
	<v-card
		:width="barWidth"
		class="mw-hover-bar elevation-6"
		:style="{ left: offsetX + 'px' }"
		@click="$emit('click')"
		@contextmenu.prevent="$emit('close')"
	>
		<span :class="{ hidden: zoomBracket < 3 }"></span>
	</v-card>
</template>

<style lang="less">
.mw-hover-bar {
	position: absolute;
	height: 110%;
	border-radius: 20px !important;
	top: -5%;
	z-index: 9;
}
</style>
<script>
export default {
	name: "SchedulerHoverBar",
	inject: ["getTimeSeriesSlotFromPosition", "sessionLength"],
	props: {
		offsetX: { type: Number },
		colWidth: { type: Number },
		zoomBracket: { type: Number },
		timeSeriesSlots: {type: Array, default: () => ([])}
	},
	computed: {
		barWidth(){
			return this.colWidth * (this.sessionLength / 15);
		}
	}
};
</script>
