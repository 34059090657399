<template>
	<thead class="mw-grid-header">
		<tr>
			<th
				v-for="(c, i) in fixedColumns"
				class="fixed-column"
				:rowspan="rows.length + 1"
				:style="{left: `${getFixedColumnOffset(i)}px`}"
				:key="`header-row-${i}-col-fixed`"
			>
				<div :style="{ width: fixedColumnWidth }">
					{{ c.text }}
				</div>
			</th>
		</tr>
		<tr id="row-years" v-if="years.length > 1">
			<td
				v-for="(slot, i) in years"
				:key="`year-${i}`"
				:colspan="slot.count"
				class="mw-grid-header-cell"
			>
				<div>
					{{ slot.year }}
				</div>
			</td>
		</tr>
		<tr id="row-days">
			<td
				v-for="(slot, i) in days"
				:key="`day-${i}`"
				:colspan="slot.count"
				class="mw-grid-header-cell"
			>
				<div>
					<span :class="{ hidden: zoomBracket < 2 }">{{
						slot.dayName 
					}}</span>
					{{ slot.ordinal }}
					
					<span :class="{ hidden: zoomBracket < 1 }">{{
						slot.monthName 
					}}</span>
				</div>
			</td>
		</tr>
		<tr id="row-timeSeriesSlots">
			<td
				v-for="(slot, i) in timeSeriesSlots"
				:key="i"
				class="mw-grid-header-cell"
				:id="i"
				:class="{ collapse: zoomBracket == 1 }"
				:width="scrollingColumnWidth"
			>
				<div v-if="zoomBracket > 1" :style="{ width: scrollingColumnWidth + 'px' }" :class="{ 'hide-3': zoomBracket == 2 || zoomBracket == 3,  'hide-2': zoomBracket == 4 } ">
					<span>{{ slot.time | moment("HH:mm") }}</span>
				</div>
			</td>
		</tr>
	</thead>
</template>

<style lang="less">
@import "./../../assets/css/variables";
thead {
	td, th {
		div {
			width: 100%;
		}
	}
}
.mw-grid-header {
	position: sticky;
	th {
		text-align: left;
	}
	.mw-grid-header-cell {
		padding: 0px  !important;
		div{
			float: right;
		}
		.hide-2, .hide-3 {
			display: none;
		}
		&:nth-child(odd){
			.hide-2 {
				display: block;
			}
		}
		&:nth-child(4n+1){
			.hide-3 {
				display: block;
			}
		}
		&.collapse {
			height: 1px;
		}
		padding-left: 0px !important;
		padding-right: 0px !important;
		-webkit-transition: width 1s ease-in-out;
		-moz-transition: width 1s ease-in-out;
		-o-transition: width 1s ease-in-out;
		transition: width 1s ease-in-out;
		div {
			padding: 0px;
		}
	}
	#row-days {
		
		.mw-grid-header-cell {
			div {
				position: relative;
			}
			div::after {
				border-right: 1px solid #ccc;
				content: " ";
				height: 100px;
				background: #ccc;
				position: absolute;
				z-index: 10;
				right: 0;
			}
		}
	}

	#row-years,
	#row-months,
	#row-days {
		// background-color: lighten(@primary, 5%);
		&:nth-child(even) {
			// background-color: lighten(@primary, 20%);
		}
		td {
			div {
				text-align: center;
			}
		}
	} 
	#row-timeSeriesSlots {
		td {
			div {
				text-align: left;
				// padding-left: 2px;
			}
		}
	}
}
</style>
<script>
import Vue from "vue";
export default {
	name: "SchedulerHeader",
	props: {
		fixedColumnWidth: { type: Number },
		zoomBracket: { type: [String, Number] },
		headerKeys: { type: Array },
		timeSeriesSlots: { type: Array },
		scrollingColumnWidth: { type: Number },
	},
	inject: [
		"fixedColumns","getFixedColumnOffset"
	],
	data: () => {
		return {
			
		}
	},
	computed: {
		rows() {
			let rows = ["years", "days", "timeSeriesSlots"];
			if( this.years.length == 1) {
				rows = rows.filter( a => a !== "years")
			}
			return rows;
		},
		years() {
			let years = this.timeSeriesSlots.map((h) => h.year);
			let uniqueYears = [...years].filter(
				(y, i, arr) => arr.indexOf(y) === i
			);
			return uniqueYears.map((year) => {
				let count = years.filter((y1) => y1 === year);
				count = count.length;
				return { year, count };
			});
		},
	
		days() {
			const self = this;
			let days = self.timeSeriesSlots.map((slot) => {
				return slot.time.format("DD-MM-YYYY");
			});
			let uniqueDays = [...days].filter((d, i, arr) => arr.indexOf(d) == i);
			return uniqueDays.map((d1) => {
				let moment = Vue.moment(d1, "DD-MM-YYYY");
				return {
					count: days.filter((d2) => d1 === d2).length,
					dayName: moment.format("dddd"),
					dayNumber: moment.format("DD"),
					ordinal: moment.format("Do"),
					monthName: moment.format("MMMM"),
				};
			});
		},
	},
};
</script>
