<template>
	<v-sheet>
		<v-sheet class="pl-5 pr-5">
			<div class="mw-grid-wrapper" ref="grid-wrapper" @wheel.prevent="wheel">
				<div class="mw-grid" ref="grid">
					<v-simple-table color="lightest">
						<scheduler-header
							:fixed-column-width="fixedColumnWidth"
							:timeSeriesSlots="timeSeriesSlots"
							:zoom-bracket="zoomBracket"
							:header-keys="headerKeys"
							:scrolling-column-width="colWidth"
						></scheduler-header>
						<tbody class="mw-grid-body">
							
							<tr
								v-for="(row, rowIndex) in rows"
								:key="`row-${rowIndex}`"
								style="position: relative"
								@click="focus = rowIndex"
							>
								<td
									v-for="(c, colIndex) in fixedColumns"
									:key="`${rowIndex}-body-row-${colIndex}-col-fixed`"
									class="fixed-column"
									:style="{
										left: `${getFixedColumnOffset(colIndex)}px`,
									}"
								>
									<div style="{width: fixedColumnWidth}">
										{{ row[c.value] }}
									</div>
								</td>
								<td
									:colspan="timeSeriesSlots.length"
									style="padding: 0px"
								>
									<scheduler-row
										:timeSeriesSlots="timeSeriesSlots"
										:focus="focus == rowIndex"
										:row="row"
										:col-width="colWidth"
										:row-height="rowHeight"
										:session-length="sessionLength"
										:zoom-bracket="zoomBracket"
									></scheduler-row>
								</td>
							</tr>
						</tbody>
						<scheduler-grid-footer
							:appointments="appointments"
							:timeSeriesSlots="timeSeriesSlots"
							:col-width="colWidth"
						></scheduler-grid-footer>
					</v-simple-table>
				</div>
			</div>
		</v-sheet>
	</v-sheet>
</template>

<style lang="less">
@import "./../../assets/css/variables";
.hidden {
	display: none;
}
.mw-grid-wrapper {
	position: relative;
	.booked-slot {
		background-color: rgb(160, 4, 4);
	}
	.available-slot {
		background-color: lighten(@primary, 30%);
	}
	.mw-grid {
		table {
			// table-layout: fixed;
			.fixed-column {
				background-color: white;
				position: sticky;
				width: 50px;
				left: 0;
				z-index: 10;
			}
		}
	}
}
</style>

<script>
import SchedulerHeader from "@c/scheduler/SchedulerHeader.vue";
import SchedulerRow from "@c/scheduler/SchedulerRow.vue";
import SchedulerGridFooter from "@c/scheduler/SchedulerGridFooter.vue";

export default {
	name: "SchedulerGrid",
	props: {
		headerKeys: { type: Array, default: () => [] },
		rows: { type: Array, default: () => [] },
		timeSeriesSlots: { type: Array, default: () => [] },
		minZoom: { type: Number, default: 0 },
		maxZoom: { type: Number, default: 100 },
		zoomLevel: { type: Number },
		zoomIncrement: { type: Number, default: 10 },
		appointments: { type: Array },
	},
	inject: ["fixedColumns", "sessionLength"],
	data: () => {
		return {
			minColWidth: 10,
			maxColWidth: 50,
			minRowHeight: 15,
			maxRowHeight: 40,
			fixedColumnWidth: 100,
			focus: null,
		};
	},
	provide() {
		return {
			fixedColumnWidth: this.fixedColumnWidth,
			getFixedColumnOffset: this.getFixedColumnOffset,
		};
	},
	components: {
		SchedulerHeader,
		SchedulerRow,
		SchedulerGridFooter,
	},
	computed: {
		colWidth() {
			let availableIncrease = this.maxColWidth - this.minColWidth;
			let current = availableIncrease * (this.zoomLevel / 100);
			return current + this.minColWidth;
		},
		rowHeight() {
			let availableIncrease = this.maxRowHeight - this.minRowHeight;
			let current = availableIncrease * (this.zoomLevel / 100);
			return current + this.minRowHeight;
		},
		zoomBracket() {
			if (this.zoomLevel > 75) {
				return 5;
			}
			if (this.zoomLevel > 45) {
				return 4;
			}
			if (this.zoomLevel > 25) {
				return 3;
			}
			if (this.zoomLevel > 10) {
				return 2;
			}
			return 1;
		},
	},
	methods: {
		getFixedColumnOffset(i) {
			return this.fixedColumnWidth * i;
		},
		wheel($event) {
			let up = $event.deltaY > 0;
			if (!$event.ctrlKey) {
				let el = this.$refs["grid-wrapper"].querySelectorAll(
					".v-data-table__wrapper"
				)[0];
				if (up) {
					el.scroll({
						left: el.scrollLeft - 50,
						top: el.scrollTop,
						behaviour: "smooth",
					});
				} else {
					el.scroll({
						left: el.scrollLeft + 50,
						top: el.scrollTop,
						behaviour: "smooth",
					});
				}
			} else {
				let newZoomLevel = this.zoomLevel;

				if (up) {
					newZoomLevel -= this.zoomIncrement;
				} else {
					newZoomLevel += this.zoomIncrement;
				}
				if (newZoomLevel <= this.maxZoom && newZoomLevel >= this.minZoom) {
					this.$emit("input", "zoomLevel", newZoomLevel);
				}
			}
		},
	},
};
</script>
