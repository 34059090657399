<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on }">
			<td
				v-on="on"
				class="mw-grid-footer-cell"
				:class="{ 'booked-slot': booking, 'available-slot': !booking }"
			>
				<div :style="{ width: colWidth + 'px' }"></div>
			</td>
		</template>
		<v-card v-if="booking">
			<v-card-text> Appointment booked for {{ mwtimeutils.formatAsDateTimeString(booking.start )}}</v-card-text>
		</v-card>
	</v-menu>
</template>

<style lang="less">
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
	height: 8px !important;
}
</style>
<script>
export default {
	name: "FooterScheduleSlot",

	props: {
		appointments: { type: Array },
		time: { type: Object },
		colWidth: { type: Number },
	},

	components: {},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		booking() {
			const self = this;
			let appointments = self.appointments;
			let match = appointments.find((a) => {
				return (
					self.time.isSameOrAfter(a.start) && self.time.isBefore(a.end)
				);
			});
			return match;
		},
	},
};
</script>
