<template>
	<v-card color="lightest" flat class="mt-3 " >
		<v-card-title>Key</v-card-title>
		<v-card-text>
			<v-chip class="striped-background ma-1">Available</v-chip>
			<v-chip color="error" dark class="ma-1">Unavailable </v-chip>
			<v-chip color="primary" dark class="ma-1">Preferred </v-chip>
			<v-chip color="success" dark class="ma-1">Booked </v-chip>
            
		</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <scheduler-instructions></scheduler-instructions>
        </v-card-actions>
	</v-card>
</template>

<style lang="less">
@import "./../../assets/css/variables";
</style>

<script>
import SchedulerInstructions from '@c/scheduler/SchedulerInstructions.vue';
export default {
	name: "SchedulerKey",
	components: {
		SchedulerInstructions
	}
};
</script>
