<template>
	<tfoot>
		<tr>
			<td
				v-for="(c, colIndex) in fixedColumns"
				:key="`footer-row-${colIndex}-col-fixed`"
				class="fixed-column"
				:style="{ left: getFixedColumnOffset(colIndex) }"
			>
				<div style="{width: fixedColumnWidth}"></div>
			</td>
			<footer-schedule-slot :colWidth="colWidth" :appointments="appointments" v-for="(slot, i) in timeSeriesSlots" :key="i" v-bind="slot"></footer-schedule-slot>
		</tr>
	</tfoot>
</template>

<style lang="less">
.mw-grid-footer-cell {
	padding: 0px !important;
}
</style>
<script>
import FooterScheduleSlot from './FooterScheduleSlot.vue';
export default {
	name: "SchedulerFoot",

	inject: [
		"fixedColumns",
		"fixedColumnWidth",
		"timeSeriesSlots",
		"getFixedColumnOffset",
	],
	props: {
		appointments: { type: Array },
		colWidth: { type: Number },
		timeSeriesSlots: { type: Array, default: () => [] },
	},
	components: {
		FooterScheduleSlot
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	methods: {
	},
};
</script>
