<template>
	<v-sheet flat v-if="project">
		<h4>{{project.internal_name}}</h4>
		<scheduler
			v-if="project.slots"
			:slots="slots"
			:session-length="parseInt(project.session_length)"
			:participants="participantAvailability"
			:appointments="appointments"
		></scheduler>
	</v-sheet>
</template>

<script>
import Scheduler from "@c/scheduler/Scheduler";

export default {
	name: "EditProjectSchedule",
	props: {
		id: { type: String },
		filter: { type: Array, default: () => [] },
	},
	data: () => {
		return {};
	},
	components: {
		Scheduler,
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		title(){
			return this.project.internal_name || null;
		},
		slots(){
			return this.mwutils.sortByDate(this.project.slots, "start", -1)
		},
		participantAvailability() {
			const self = this;
			let p = self.$store.getters.getByKey(
				"participantAvailability",
				"project",
				self.id
			);
			if (self.filter.length) {
				p = p.filter((p) => self.filter.includes(p.participant));
				p.sort((a, b) => {
					var x = self.filter.indexOf(a.participant);
					var y = self.filter.indexOf(b.participant);
					return x < y ? -1 : x > y ? 1 : 0;
				});
			}
			if (
				self.project.exclude_participants &&
				self.project.exclude_participants.length
			) {
				p = p.filter(
					(p) => !self.project.exclude_participants.includes(p.participant)
				);
			}
			return p;
		},
		appointments() {
			const self = this;
			let appointments = this.$store.getters['appointments/activeAppointmentsForProject'](self.id);
			appointments = appointments.filter( a => a.start );
			return appointments.map( a => {
				a.start = self.mwtimeutils.parseDateToMoment(a.start);
				if( !a.end ){
					a.end = a.start.clone().add(self.project.session_length, "minutes");
				}
				return a;
			})
		},
	},
	provide() {
		return {
			projectId: this.id,
		};
	},
	methods: {
		setTitle(v) {
			this.$store.commit("SET_TITLE", v  );
		},
		update(key, value) {
			this.$store.dispatch("projects/patch", {
				id: this.id,
				[key]: value,
			});
		},
	},
	watch: {
		title: {
			immediate: true, 
			handler(v){
				if( v ){

					this.setTitle(v);
				}
			}
		}
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
		this.$store.dispatch("participantAvailability/fetchByProject", this.id);
		this.$store.dispatch("appointments/fetchByProject", this.id);
	},
};
</script>
