<template>
				<mw-dialog v-model="dialog" cancelBtn small>
					<template v-slot:button>
						<v-btn small rounded text @click="dialog = true"
							><v-icon small>mdi-open-in-new</v-icon>&nbsp;More Information
						</v-btn>
					</template>
					<template v-slot:dialog-body>
						<p>
							Right click any coloured bar for more information and
							options.
						</p>
						<p>
							Double click anywhere inside the row to start a new
							booking. Moving the mouse will move the bar. Right click on
							the bar to delete it. Click on the bar to see more details
							and confirm the booking.
						</p>
						<p>
							Using the mouse wheel while inside the grid will scroll the
							grid left to right. Holding down the ctrl key at the same
							time will zoom in and out.
						</p>
                        
					</template>
				</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "SchedulerInstructions",
    data: () => {
        return {
            dialog: false
        }
    },
	components: {
		MwDialog,
	},
};
</script>
