<template>
	<div
		class="mw-scheduler-row"
		id="row1"
		:style="{ height: rowHeight + 'px' }"
		@dblclick="newSession = true;"
		@mousemove="mouseMove"
	>
		<scheduler-hover-bar
			v-if="newSession"
			:col-width="colWidth"
			:offset-x="offsetX"
			@click="book(offsetX)"
			@close="close"
			:zoom-bracket="zoomBracket"
			:timeSeriesSlots="timeSeriesSlots"
		></scheduler-hover-bar>
		<scheduler-bar
			v-for="(bar, i) in bars"
			:key="`bar-${i}`"
			v-bind="bar"
			:col-width="colWidth"
			:row="row"
			:appointment="bar.appointment"
			:timeSeriesSlots="timeSeriesSlots"
			@book="(v) => book(v)"
			@dblclick="newSession = true"
		></scheduler-bar>
		<new-booking
			:value="dialog" @cancel="dialog = false"
			:row="row"
			:timeSeriesSlots="timeSeriesSlots"
			:hour="focusHour"
			:bars="bars"
			@close="close"
		></new-booking>
	</div>
</template>
<style lang="less">
.mw-scheduler-row {
	cursor: pointer;
	position: relative;
}
</style>
<script>
import SchedulerBar from "@c/scheduler/SchedulerBar";
import SchedulerHoverBar from "@c/scheduler/SchedulerHoverBar.vue";
import NewBooking from "@c/scheduler/NewBooking";
export default {
	name: "ScheduerRow",
	props: {
		row: { type: Object },
		rowHeight: { type: Number },
		colWidth: { type: Number },
		zoomBracket: { type: Number },
		focus: {type: Boolean },
		timeSeriesSlots: {type: Array, default: () => ([])}
	},
	inject: ["sessionLength"],
	provide() {
		return {
			getTimeSeriesSlotFromPosition: this.getTimeSeriesSlotFromPosition,
		};
	},
	data: () => {
		return {
			newSession: false,
			offsetX: null,
			dialog: false,
			focusHour: null,
		};
	},
	components: {
		SchedulerHoverBar,
		SchedulerBar,
		NewBooking
	},
	computed: {
		appointments(){
			return this.$store.state.appointments.data;
		},
		bars() {
			const self = this;
			let bars = self.row.availability.map( (a) => {
				return {
					sessionStatus: a.status, 
					startAt: self.findClosest(a.start), 
					endAt: self.findClosest(a.end), 
					type: self.getType(a) 
				}
			})

			self.row.bookings.forEach( (a) => {
				let appointment = self.appointments[a.appointment]
				bars.push({
					appointment: a.appointment,
					appointmentParticipant: a.appointmentParticipant,
					sessionStatus: "booked", 
					startAt: self.findClosest(appointment.start), 
					endAt: self.findClosest(appointment.end), 
					type: "session" 
				} )
			})

			return bars;
		},
	},
	methods: {
		setOffset(v) {
			this.offsetX = v;
		},
		setSlot(pos) {
			this.focusHour = this.getTimeSeriesSlotFromPosition(pos);
		},
		book(v) {
			this.setSlot(v);
			this.dialog = true;
		},
		mouseMove($event) {
			if( !this.focus ){
				return;
			}
			let elDimensions = this.$el.getBoundingClientRect();
			let parentOffset = elDimensions.x;
			this.setOffset($event.clientX - parentOffset);
			
		},

		getTimeSeriesSlotFromPosition(pos) {
			if (!pos) {
				return 0;
			}
			return Math.floor(pos / this.colWidth);
		},
		close(){
			this.newSession = false;
			this.dialog = false;
		},
		getType(av){
			var duration = this.mwtimeutils.length(av);
			if( duration == this.sessionLength ){
				return "session";
			}
			return "period";
		}, 
		findClosest(t) {
			const self = this;
			let match = self.timeSeriesSlots.find( a => a.time.isSame(t) );
			if( !match ){
				let greater = self.timeSeriesSlots.find( a => a.time > t );
				return self.timeSeriesSlots.indexOf(greater) - 1;
			}
			return self.timeSeriesSlots.indexOf(match);

		}
	},
};
</script>

<style lang="less">
.mw-grid-body {
	tr {
		td {
			.mw-scheduler-row {
				position: relative;
				z-index: 0;
				background-color: white;
				width: 100%;
			}
		}
	}
}
</style>
