<template>
	<mw-dialog
		:value="value"
		@input="toggle"
		cancelBtn
		submitBtn
		@submit="submit"
		@cancel="toggle(false)"
	>
		<template v-slot:dialog-body>
			<v-card flat v-if="slot">
				<p>Booking an appointment for {{ participantName }}.</p>
				<p>
					{{ sessionLength }} minutes from
					<span>{{ slot.time | moment("HH:mm") }}</span> on
					<span>{{ slot.time | moment("dddd, MMMM Do YYYY") }}</span
					>.
				</p>
				<v-checkbox
					hide-details
					class="mt-0"
					v-model="sendMail"
					label="Send a confirmation email"
				></v-checkbox>
				<v-checkbox
					hide-details
					class="mt-0"
					v-if="existingBooking"
					v-model="removeOtherBookings"
					label="Remove other bookings for this participant"
				></v-checkbox>
			</v-card>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "NewBooking",
	props: {
		value: { type: Boolean },
		row: { type: Object },
		hour: { type: Number },
		bars: { type: [Array, Object] },
		timeSeriesSlots: { type: Array, default: () => [] },
	},
	inject: ["sessionLength", "projectId"],
	data: () => {
		return {
			sendMail: true,
			removeOtherBookings: false,
		};
	},
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		participantName() {
			return this.users[this.row.participant]
				? this.users[this.row.participant].name
				: false;
		},
		slot() {
			return this.timeSeriesSlots[this.hour] || false;
		},
		existingBooking() {
			return this.bars.find((a) => a.sessionStatus == "booked");
		},
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		toggle(v) {
			if (!v) {
				this.$emit("close");
			}
		},
		submit() {
			const self = this;
			let user = self.row.participant;
			let doc = {
				project: self.projectId,
				methodology: self.project.methodology || "",
				duration: self.sessionLength,
				appointment_status: "provisional",
			};
			if (self.slot.time) {
				doc.start = self.slot.time.toDate();
			}
			self.$store.dispatch("appointments/insert", doc).then((id) => {
				self.$store
					.dispatch("appointmentParticipants/insert", {
						user,
						appointment: id,
						response: self.row.response,
					})
					.then((appointment_participant) => {
						if (self.sendMail) {
							self.$store
								.dispatch("emails/sendOut", {
									email: "appointment_confirmation",
									values: { appointment_participant },
								})
								.then(() => {
									self.$root.$emit("showToast", "Sent");
								})
								.catch(() => {
									self.$root.$emit("showToast", "Error sending", true);
								});
						}
					});
				if (self.removeOtherBookings) {
					self.row.bookings.forEach((b) => {
						if (b.appointment !== id) {
							self.$store.dispatch(
								"appointmentParticipants/cancel",
								b.appointmentParticipant
							);
						}
					});
				}
				self.$emit("close");
			});
		},
	},
};
</script>
