<template>
	<v-sheet>
		<scheduler-grid
			:zoom-level="zoomLevel"
			@input="update"
			:appointments="appointments"
			:rows="rows"
			:timeSeriesSlots="timeSeriesSlots"
		></scheduler-grid>

		<secondary-navigation show-back>
			<template v-slot:main>
				<scheduler-toolbar
					@input="update"
					:zoom-level="zoomLevel"
					:timezone="timezone"
					@hidePast="v => pastIsHidden = v"
					:past-is-hidden="pastIsHidden"
				></scheduler-toolbar>
			</template>
			<template v-slot:append> </template>
		</secondary-navigation>
		<scheduler-key></scheduler-key>
	</v-sheet>
</template>

<script>
import SchedulerGrid from "@c/scheduler/SchedulerGrid.vue";
import SecondaryNavigation from "@c/navigation/Secondary";
import SchedulerToolbar from "@c/scheduler/SchedulerToolbar.vue";
import SchedulerKey from "@c/scheduler/SchedulerKey.vue";

export default {
	name: "Scheduler",
	props: {
		slots: { type: Array },
		sessionLength: { type: [String, Number], default: 60 },
		participants: { type: Array },
		appointments: { type: Array },
	},
	components: {
		SchedulerGrid,
		SecondaryNavigation,
		SchedulerToolbar,
		SchedulerKey,
	},
	data() {
		return {
			zoomLevel: 0,
			maxZoom: 100,
			minZoom: 0,
			timezone: null,
			timeSeriesSlots: [],
			pastIsHidden: false,
		};
	},
	computed: {
		includedSlots() {
			const self = this;
			let now = self.mwtimeutils.parseDateToMoment(new Date());
			if (!self.pastIsHidden) {
				return self.slots;
			} else {
				return self.slots.filter((a) => {
					let end = self.mwtimeutils.parseDateToMoment(a.end.toDate());
					return end.isSameOrAfter(now) 
				})
			}
		},
		appointmentParticipants(){
			let ids = this.appointments.map(a => a.id );
			return this.$store.getters.getByKeyIn("appointmentParticipants", "appointment", ids )
		},
		users() {
			return this.$store.state.users.data;
		},
		systemTimezone() {
			return this.$store.state.timezone;
		},
		columns() {
			let columns = [
				{ value: "participant_name", text: "Participant Name" },
			];
			return columns;
		},
		rows() {
			const self = this;
			return self.participants.map((p) => {
				let id = p.participant;
				let user = self.users[id];
				return {
					participant: id,
					response: p.response,
					participant_name: user ? user.name : "-",
					availability: p.availability.map((a) => {
						a.start = self.mwtimeutils.parseDateToMoment(a.start);
						a.end = self.mwtimeutils.parseDateToMoment(a.end);
						return a;
					}),
					bookings: self.getBookings(id),
				};
			});
		},
	},
	methods: {
		update(key, value) {
			this[key] = value;
		},
		makeTimeSeriesSlots() {
			const self = this;
			let quarters = this.mwtimeutils.getQuartersFromSlots(
				self.includedSlots,
				self.timezone
			);
			self.timeSeriesSlots = quarters.map((slot) =>
				self.makeTimeSeriesSlot(slot)
			);
		},
		makeTimeSeriesSlot(slot) {
			return {
				time: slot,
				year: slot.year(),
				month: slot.month(),
				date: slot.date(),
				hour: slot.hour(),
				minute: slot.minute(),
			};
		},
		getBookings(id) {
			if (!this.appointmentParticipants) {
				return [];
			}
			let appointmentParticipants = this.appointmentParticipants.filter((a) =>
				a.user === id && a.appointment_status !== "cancelled"
			);
			return appointmentParticipants.map( a => ( {appointmentParticipant: a.id, appointment: a.appointment  }) );
		},
	},
	watch: {
		includedSlots: {
			immediate: true,
			handler() {
				this.makeTimeSeriesSlots();
			},
		},
		zoomLevel() {
			this.$root.$emit("zoom");
		},
		timezone: {
			immediate: true,
			handler() {
				this.makeTimeSeriesSlots();
			},
		},
	},
	provide() {
		return {
			timeSeriesSlots: this.timeSeriesSlots,
			sessionLength: this.sessionLength,
			rows: this.rows,
			fixedColumns: this.columns,
		};
	},
};
</script>

<style scoped></style>
