<template>
	<v-card>
		<v-list>
			<v-list-item>
				<v-list-item-avatar :color="avatarColor">
					<v-icon color="white">{{ avatarIcon }}</v-icon>
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title>{{ title }}</v-list-item-title>
					<v-list-item-subtitle>{{ timeline }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider></v-divider>
		<v-list dense>
			<v-list-item
				v-for="(action, i) in actions"
				:key="`action-${i}`"
				:router-link="action['router-link']"
				:to="action.to"
				@click="($event) => (action.click ? action.click($event) : null)"
			>
				{{ action.text }}
			</v-list-item>
		</v-list>
	
		<v-card-actions>
			<span>
				<v-chip v-if="appointmentStatus" :color="statusColors[appointmentStatus]" class="mr-2">
					{{appointmentStatus}}
				</v-chip>
				<v-chip v-if="quota" color="primary" class="mr-2">
					{{quota}}
				</v-chip>
			</span>
			<v-spacer></v-spacer>
			<v-btn color="primary" text @click="$emit('close')">
				Close
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { arrayUnion } from 'vuex-easy-firestore';
export default {
	name: "SchedulerBarMenu",
	props: {
		appointment: { type: String },
		appointmentParticipant: { type: String },
		row: { type: [Object] },
		startAt: { type: Number },
		endAt: { type: Number },
		type: { type: String },
		clickPos: {type: Number, default: 0 },
		sessionStatus: { type: String },
		timeSeriesSlots: { type: Array, default: () => [] },
	},
	inject: [ "sessionLength", "projectId"],
	data: () => ({}),
	methods: {
		bookAppointment() {
			this.$emit("book", this.clickPos )
			this.$emit("close");
		},
		cancelBooking(){
			this.$store.dispatch("appointmentParticipants/cancel", this.appointmentParticipant );
			this.$emit("close");
		},
		editBooking(){
			this.$router.push({name: "ProjectAppointments", params: {id: this.projectId, appointmentId: this.appointment, appointmentParticipantId: this.appointmentParticipant  } })
		},
		excludeParticipant(){
			this.$store.dispatch("projects/patch", {id: this.projectId, exclude_participants: arrayUnion(this.row.participant)})
			this.$emit("close");
		}
	},
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		statusColors(){
			return this.$store.state.status_colors
		},
		actions() {
			let actions = [
				{
					["router-link"]: true,
					to: `/users/${this.row.participant}`,
					text: "View User",
				},
				{
					text: "Exclude Participant",
					click: this.excludeParticipant,
				},
			];
			if (this.type == "session") {
				if (this.sessionStatus !== "booked") {
					actions.push({
						text: "Book Appointment",
						click: this.bookAppointment,
					});
				} else {
					actions.push({
						text: "Edit Booking",
						click: this.editBooking,
					});
					actions.push({
						text: "Cancel Booking",
						click: this.cancelBooking,
					});
				}
			}
			return actions;
		},
		avatarColor() {
			return this.statusColors[this.sessionStatus];
		},
		avatarIcon() {
			let icons = {
				preferred: "mdi-heart",
				available: "mdi-circle",
				booked: "mdi-check-circle",
				unavailable: "mdi-close-circle",
			};
			return icons[this.sessionStatus];
		},
		statusText() {
			let text = {
				preferred: "'s Preference",
				available: " is Available",
				unavailable: " is Unvailable",
				booked: "'s Appointment",
			};
			return text[this.sessionStatus];
		},
		participantName(){
			return this.users[this.row.participant].name
		},
		appointmentStatus(){
			let status = "";
			if( this.sessionStatus == "booked") {
				status = this.$store.state.appointmentParticipants.data[this.appointmentParticipant].appointment_status;
			}
			return this.mwutils.prettyPrint(status, "TITLE");
		},
		quota(){
			if( this.sessionStatus == "booked") {
			return this.$store.state.appointmentParticipants.data[this.appointmentParticipant].quota;
			}
			return "";
		},
		title() {
			return this.participantName + this.statusText;
		},
		timeline() {
			let str = this.timeSeriesSlots[this.startAt].time.clone().format("DD/MM/YYYY HH:mm");
			str += " - ";
			if (this.endAt) {
				str += this.timeSeriesSlots[this.endAt].time.clone()
					.format("DD/MM/YYYY HH:mm");
			}
			else{
				str += this.timeSeriesSlots[this.startAt].time.clone().add(this.sessionLength, "minutes")
					.format("DD/MM/YYYY HH:mm");
			}
			return str;
		},
	},
};
</script>
