<template>
	<div>
		<div
			class="mw-scheduler-bar"
			@dblclick="$emit('dblclick')"
			@click="$emit('click')"
			@contextmenu.prevent="openMenu"
			:style="barStyle"
			:class="{ [sessionStatus]: true }"
		></div>
		<v-menu
			v-model="menu"
			:close-on-click="false"
			:nudge-width="200"
			:position-x="x"
			:position-y="y"
			:max-width="400"
		>
			<scheduler-bar-menu
				v-bind="$props"
				:timeSeriesSlots="timeSeriesSlots"
				@close="menu = false"
				:click-pos="offsetX"
				v-on="$listeners"
			></scheduler-bar-menu>
		</v-menu>
	</div>
</template>

<style lang="less">
@import "./../../assets/css/variables";
.mw-scheduler-bar {
	height: 100%;
	border-radius: 20px;
	position: absolute;
	&.available {
		.striped-background();
	}
	&.preferred {
		background-color: @primary;
		z-index: 4;
	}
	&.unavailable {
		background-color: @error;
		z-index: 4;
	}
	&.booked {
		background-color: @success;
		z-index: 7;
	}
	&.session {
		z-index: 7;
	}
}
</style>

<script>
import SchedulerBarMenu from "@c/scheduler/SchedulerBarMenu";

export default {
	name: "SchedulerBar",
	props: {
		appointment: { type: String },
		appointmentParticipant: { type: String },
		type: { type: String },
		sessionStatus: { type: String },
		startAt: { type: Number },
		endAt: { type: Number },
		row: { type: Object },
		colWidth: { type: Number },
		timeSeriesSlots: { type: Array, default: () => [] },
	},
	data: () => {
		return {
			menu: false,
			width: null,
			left: null,
			x: null,
			offsetX: null,
			y: null,
		};
	},
	inject: [ "sessionLength", "fixedColumns", "getFixedColumnOffset"],
	components: {
		SchedulerBarMenu,
	},
	computed: {
		barStyle() {
			let style = {
				width: this.width + "px",
				left: this.left + "px",
			};
			return style;
		},
	},
	methods: {
		openMenu($event) {
			this.x = $event.clientX;
			this.offsetX = $event.offsetX + this.left;
			this.y = $event.clientY;
			this.menu = true;
		},
		calculatePeriodBarWidth() {
			return (this.endAt - this.startAt) * this.colWidth;
		},
		calculateSessionBarWidth() {
			let s = this.sessionLength / 15;
			let w = this.colWidth * s;
			return w;
		},
		calculateWidth() {
			if (this.type == "period") {
				this.width = this.calculatePeriodBarWidth();
			} else {
				this.width = this.calculateSessionBarWidth();
			}
		},
		calculatePosition() {
			this.left = this.startAt * this.colWidth;
		},
		calculateBar() {
			this.calculateWidth();
			this.calculatePosition();
		},
	},
	watch: {
		colWidth() {
			this.calculateBar();
		},
		startAt() {
			this.calculateBar();
		},
	},
	mounted() {
		window.addEventListener("resize", this.calculateBar);
		this.calculateBar();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.calculateBar);
	},
};
</script>
